
import {defineComponent, ref} from 'vue';
import {useRouterCustom} from '@/core/hooks/use-router-custom';
import {swalErrNotification, swalNotification} from '@/core/helpers/utils';
import {IDanhMucVatTuResponseBase, IVatTuResponseBase} from '@/core/interfaces/ApiResponses';
import {useDateTime} from '@/core/hooks/use-date-time-hook';
import {setCurrentPageTitle} from '@/core/helpers/breadcrumb';
import {ButtonsType, ButtonTypeColors} from '@/core/enums/buttons.enums';
import {useLoading} from '@/core/hooks/use-loading';
import { usePagination } from '../../../../core/hooks/use-pagination';
import { VatTuService } from '../../../../core/services/VatTu.service';

import DanhMucVatTuService from '@/core/services/DanhMucVatTu.service';
import DanhSachVatTuTable from '@/views/crafted/pages/vat-tu/danh-sach-vat-tu-table/DanhSachVatTuTable.vue';
import ModalThemVatTu from '@/views/crafted/pages/danh-muc-vat-tu/modal-them-vat-tu/ModalThemVatTu.vue';
import ButtonCustom from '@/components/shared/buttons/ButtonCustom.vue';
import PageRowLayout from '../../../../layout/_shared/PageRowLayout.vue';
import Pagination from '../../../../components/shared/pagination/Pagination.vue';
import PerPageSelection from '../../../../components/shared/per-page-selection/PerPageSelection.vue';
import DivCol from '../../../../layout/_shared/DivCol.vue';
import DivRow from '../../../../layout/_shared/DivRow.vue';
import LabelCustom from '../../../../components/shared/label-custom/LabelCustom.vue';

export default defineComponent({
	name: 'chi-tiet-danh-muc-vat-tu',

	components: {
		ButtonCustom,
		DanhSachVatTuTable,
		DivCol,
		DivRow,
		ModalThemVatTu,
		LabelCustom,
		PageRowLayout,
		Pagination,
		PerPageSelection
	},

	setup() {
		const {goBack, push, id} = useRouterCustom();
		const {moment} = useDateTime();
		const { startLoading, endLoading } = useLoading();
		const { currentPage, totalItems, perPage, perPageArr } = usePagination();

		const danhMucVatTu = ref<IDanhMucVatTuResponseBase | null>(null);

		return {
			push,
			danhMucVatTuId: id,
			danhMucVatTu,
			goBack,
			moment,
			startLoading, endLoading,
			currentPage, totalItems, perPage, perPageArr,
		}
	},

	data() {
		return {
			isModalOpen: false,
			ButtonsType, ButtonTypeColors,
			vatTuItems: [] as IVatTuResponseBase[],
		}
	},

	async mounted() {
		setCurrentPageTitle('Danh mục vật tư');
		await this.fetchData();
	},

	computed: {
		totalVatTuDisplay(): string {
			if (this.danhMucVatTu && this.totalItems === 0) {
				return 'Vật tư'
			}
			const totalVatTu = this.totalItems;
			if (this.danhMucVatTu) {
				return `Vật tư (${totalVatTu} loại vật tư)`
			}
			return '';
		}
	},

	watch: {
		async currentPage() {
			await this.fetchVatTuItems();
		},

		async perPage() {
			await this.fetchVatTuItems();
		},

		danhMucVatTu: {
			handler() {
				this.fetchVatTuItems();
			},
			deep: true,
		}
	},

	methods: {
		async fetchData() {
			try {
				this.startLoading();
				const { data: { data } } = await DanhMucVatTuService.get(
					this.danhMucVatTuId
				);
				this.endLoading();
				this.danhMucVatTu = data;
			} catch (e) {
				this.endLoading();
				await swalNotification(
					'Có lỗi xảy ra khi lấy thông tin vật tư',
					'error'
				);
			}
		},

		async fetchVatTuItems() {
			try {
				this.startLoading();
				if (!this.danhMucVatTuId) {
					this.vatTuItems = [];
					this.endLoading();
					return;
				}

				const { data: { data: { data, total } } } = await VatTuService.list({
					current_page: this.currentPage,
					per_page: this.perPage,
					id_dm_vat_tu: this.danhMucVatTuId,
				});
				this.vatTuItems = data;
				this.totalItems = total;
				this.endLoading();
			} catch (e) {
				this.endLoading();
				await swalErrNotification(e, 'Lỗi khi lấy thông tin vật tư');
			}
		},

		openModal() {
			this.isModalOpen = true;
		},

		async onAddedVatTuItems() {
			this.isModalOpen = false;
			await this.fetchData();
		}
	}
})
