
import {defineComponent, PropType, ref} from 'vue';
import {IVatTuResponseBase} from '@/core/interfaces/ApiResponses';
import {swalNotification} from '@/core/helpers/utils';
import {VatTuService} from '@/core/services/VatTu.service';
import {formatCurrencyAndQuantity} from '@/core/helpers/utils';
import {ButtonsType, ButtonTypeColors} from '@/core/enums/buttons.enums';

import Pagination from '@/components/shared/pagination/Pagination.vue';
import DanhMucVatTuService from '@/core/services/DanhMucVatTu.service';
import ButtonCustom from '@/components/shared/buttons/ButtonCustom.vue';
import DivCol from '../../../../../layout/_shared/DivCol.vue';
import DivRow from '../../../../../layout/_shared/DivRow.vue';
import useVatTu from '@/core/hooks/use-vat-tu';

export default defineComponent({
	name: 'modal-them-vat-tu',

	components: { Pagination, ButtonCustom, DivCol, DivRow },

	emits: ['close', 'added'],

	props: {
		isModalOpen: {
			type: Boolean,
			default: false,
		},
		selectedVatTuItems: {
			type: Array as PropType<Array<IVatTuResponseBase>>
		},
		danhMucVatTuId: {
			type: Number,
			required: true,
		}
	},

	data() {
		return {
			searchTerm: '',
			isOpen: false,
			currentPage: 1,
			totalItems: 0,
			selectedVatTuIds: [] as number[],
			selectedVatTuIdArr: [] as boolean[],
			ButtonsType, ButtonTypeColors,
		}
	},

	setup() {
		const vatTuItems = ref<IVatTuResponseBase[]>([]);

    const { isTextDangerColumn, isValidLabel } = useVatTu();

		return {
			vatTuItems, isTextDangerColumn, isValidLabel,
		};
	},

	watch: {
		isModalOpen: {
			async handler(newValue) {
				if (newValue) {
					this.isOpen = newValue;
					this.currentPage = 1;
					await this.fetchVatTuItems(this.currentPage);
					if (this.selectedVatTuItems) {
						this.selectedVatTuIds = this.selectedVatTuItems.map(item => item.id);
						this.selectedVatTuIdArr = this.vatTuItems.map(
							item => {
								return this.selectedVatTuIds.includes(item.id)
							}
						);
					}
				}
			},
			deep: true,
		},

		async currentPage(page) {
			await this.fetchVatTuItems(page);
			if (this.selectedVatTuItems) {
				this.selectedVatTuIdArr = this.vatTuItems.map(
					item => {
						return this.selectedVatTuIds.includes(item.id)
					}
				);
			}
		}
	},

	computed: {
		totalVatTuDaChon() {
			return this.selectedVatTuIds.length;
		},

		totalVatTuDangChonTrongPage() {
			let count = 0;
			this.selectedVatTuIdArr.forEach(item => {
				if (item) count += 1;
			});
			return count;
		},

		labelDisplay() {
			return `Danh sách vật tư (đã chọn ${this.totalVatTuDaChon}, trong trang này ${this.totalVatTuDangChonTrongPage})`
		}
	},

	methods: {
		formatCurrencyAndQuantity,

		async searchForVatTu() {
			if (this.searchTerm) {
				this.currentPage = 1;

				this.fetchVatTuItems(this.currentPage)
			}
		},

		onRowClick(row) {
			const index = this.vatTuItems.findIndex(item => item.id === row.id);
			this.selectedVatTuIdArr[index] = !this.selectedVatTuIdArr[index];

			const foundIndex = this.selectedVatTuIds.findIndex(id => id === row.id);
			if (foundIndex >= 0) {
				this.selectedVatTuIds.splice(foundIndex, 1);
			} else this.selectedVatTuIds.push(row.id);
		},

		onClose() {
			this.isOpen = false;
			this.$emit('close');
		},

		async fetchVatTuItems(page) {
			try {
				const { data: { data: { data: items, total } } } = await VatTuService.list({
					current_page: page,
					per_page: 20,
					s: this.searchTerm,
				});
				this.vatTuItems = items;
				this.totalItems = total;
			} catch {
				await swalNotification(
					'Có lỗi xảy ra khi lấy danh sách vật tư',
					'error',
				)
			}
		},

		onVatTuSelected(value, vatTuId, index) {
			this.selectedVatTuIdArr[index] = value;
			const foundIndex = this.selectedVatTuIds.findIndex(id => id === vatTuId);
			if (foundIndex >= 0) {
				this.selectedVatTuIds.splice(foundIndex, 1);
			} else this.selectedVatTuIds.push(vatTuId);
		},

		async submit() {
			try {
				await DanhMucVatTuService.themVatTuItems(
					this.danhMucVatTuId,
					this.selectedVatTuIds,
				);

				this.isOpen = false;
				this.$emit('added');
			} catch {
				await swalNotification(
					'Có lỗi xảy ra khi thêm vật tư vào danh mục',
					'error',
				);
				this.onClose();
			}
		}
	}
})
